import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./Copyrights.scss";
class Copyrights extends Component {
  render() {
    return (
      <div className="container-fluid mb-5 mb-md-0">
        <div className="copyrights-text">
          <div className="row">
            <div className="col-md-6 reserved-text">
              <span className="text-white">
                Copyright © 2003 - {moment().format("YYYY")} AutoDeals (Pvt) Ltd. All Rights Reserved. Developed By <a href="https://www.linkedin.com/in/saqib-idrees-developer/">Saqib Idrees</a>
              </span>
            </div>
            <div className="col-md-6 term-service justify-content-end">
              <Link to={"/terms-of-services"} className="text-white ">
                {" "}
                Terms of Service
              </Link>{" "}
              <span className="text-white">|</span>{" "}
              <Link to={"/privacy-policy"}>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Copyrights;
