import React, { Component } from "react";
import "./Header.scss";
import TopNavigation from "./TopNavigation/TopNavigation";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import HomeBanner from "../../Home/HomeBanner/HomeBanner";
import TopBreadcrumb from "./TopBreadcrumb/TopBreadcrumb";
import TopNavigationPrivate from "./TopNavigationPrivate/TopNavigationPrivate";
import CartService from "../../../services/cart-Service";
import { connect } from "react-redux";
import { actions } from "../../../store/actions/index";
import LocalStoreService from "../../../services/localStore-service";
import Configuration from "../../../config/configuration";
import InfoService from "../../../services/info-service";
import CommonService from "../../../services/common-service";
// icons
import { GoTriangleDown } from "react-icons/go";
import { BsSearch, BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { AiOutlineTag, AiOutlineStar, AiOutlineUserSwitch } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { BiGitCompare, BiStore } from "react-icons/bi";
import { RiPriceTag3Line } from "react-icons/ri";
import { FaRegComments } from "react-icons/fa";

class Header extends Component {
  constructor() {
    super();
    this.localService = new LocalStoreService();
    this.cartService = new CartService();
    this.config = new Configuration();
    this.infoService = new InfoService();
    this.commonService = new CommonService();
    this.state = {
      socket: "",
      totalCartItems: 0,
      dropdownOpen: "0",
      isOpen: false,
      userId: 0,
      chats: [],
      unreadChatsCount: 0,
      cities: [],
      makes: [],
      active: "1",
    };
    // this.toggle = this.toggle.bind(this);
    // this.onMouseEnter = this.onMouseEnter.bind(this);
    // this.onMouseLeave = this.onMouseLeave.bind(this);
  }
  toggle = (show) => {
    if (show.type) {
      return;
    }
    this.setState(
      {
        dropdownOpen: show,
      },
      () => {}
    );
  };

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  componentDidMount() {
    this.setProfileDataToRedux();
    // this.getAllMakes();
    // this.getAdsCities();
    // this.props.socket && console.log(this.props.socket._update) && this.getAllChats();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuth !== this.props.isAuth) {
      this.props.isAuth === false && this.props.history.push("/auth/signin");
    }

    if (prevProps.cartItems !== this.props.cartItems || prevProps.socket !== this.props.socket) {
      // this.props.socket && this.getAllChats();
      let { totalCartItems } = this.state;
      totalCartItems = 0;
      this.props.cartItems.forEach((ele) => {
        totalCartItems += ele.quantity;
      });
      this.setState(
        {
          totalCartItems,
          socket: this.props.socket,
          userId: this.localService.getUserId(),
        },
        () => this.getAllChats()
      );
    }
  }

  toggler() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  getHomeBanner() {
    if (this.props.location.pathname === "/") {
      return <HomeBanner />;
    }
  }

  setProfileDataToRedux() {
    const userProfile = this.localService.getUserProfile();
    const token = this.localService.getUserToken();
    const cartItems = this.localService.getCartData();
    this.props.setCartItems(cartItems);
    if (userProfile && token) {
      this.props.setProfile(userProfile);
      this.props.setAuthStatus(true);
    } else {
      // localStorage.clear();
      this.props.setProfile(null);
      this.props.setAuthStatus(false);
    }
  }

  getAllChats = () => {
    const { socket, userId } = this.state;
    if (userId) {
      const receiverId = userId;
      this.props.socket && socket.emit("user-all-messages", receiverId, this.setResponse);
      socket.on(`initiate-chat-notification-${receiverId}`, (notificationObj, chatId) => {
        // let message = { title: notificationObj.title, chatId }
        socket.emit("user-all-messages", receiverId, this.setResponse);
      });
      socket.emit("user-all-messages", receiverId, this.setResponse);
    }
  };

  setResponse = (res) => {
    let { userId, unreadChatsCount } = this.state;
    let count = 0;
    if (res) {
      // this.setState({
      //   chats: res
      // })
      res.forEach((chat) => {
        let unreadMsgs = chat.messages.filter((message) => !message.status && message.senderId !== userId);
        if (unreadMsgs && unreadMsgs.length > 0) {
          count = unreadMsgs.length;
        }
        return count;
      });
      this.setState({ unreadChatsCount: count });
    }
  };
  onSignOutHandler = () => {
    this.setProfileDataToRedux();
  };

  sellNow = (route) => {
    this.props.history.push(route);
  };

  goToCheckout = () => {
    if (this.props.cartItems && this.props.cartItems.length > 0) {
      this.props.history.push("/checkout/order-summary");
    } else {
      return;
    }
  };

  getBreadcrumb() {
    if (this.props.location.pathname !== "/" && !this.props.location.pathname.includes("/auth")) {
      return <TopBreadcrumb />;
    }
  }

  shopingCart = () => {
    const { totalCartItems } = this.state;
    return (
      <div className="cart-icon" onClick={this.goToCheckout}>
        <i className="fas fa-shopping-cart"></i>
        <span className={`badge badge-light counter ${+totalCartItems === 0 ? "disable-cart" : ""}`}>{totalCartItems}</span>
      </div>
    );
  };

  redirectToAuction = () => {
    let url = `https://autodeals-web.firebaseapp.com/verified-auction-sheet`;
    return window.open(url, "_blank");
  };

  redirectToBlog = () => {
    let autodealsBlogs = this.config.autodealsBlogs;
    window.open(autodealsBlogs, "_self");
  };

  // get cities
  cities = [
    {
      name: "Lahore",
      image: require("../../../assets/Images/city/Cities icons-01.png"),
    },
    {
      name: "Islamabad",
      image: require("../../../assets/Images/city/Cities icons-02.png"),
    },
    {
      name: "karachi",
      image: require("../../../assets/Images/city/Cities icons-03.png"),
    },
    // { name: "Rawalpindi", image: require("../../../assets/Images/city/Cities icons-04.png") },
    {
      name: "Peshawar",
      image: require("../../../assets/Images/city/Cities icons-05.png"),
    },
    {
      name: "Sialkot",
      image: require("../../../assets/Images/city/Cities icons-06.png"),
    },
    {
      name: "Gujranwala",
      image: require("../../../assets/Images/city/Cities icons-07.png"),
    },
  ];
  getAdsCities = () => {
    const { category } = this.state;
    this.infoService
      .getAdsCities(category, 1, 9)
      .then((res) => {
        console.log(res.data);
        const { data } = res;
        if (data && data.length) {
          this.setState({
            cities: data,
          });
        }
      })
      .catch((err) => {
        this.infoService.handleError(err);
      });
  };

  // makes
  makes = [
    {
      id: 7,
      name: "Toyota",
      image: "https://assets-autodeals.s3.eu-west-1.amazonaws.com/1667311340545car%20brands%20icons-10.png",
    },
    {
      id: 8,
      name: "Suzuki",
      image: "https://assets-autodeals.s3.eu-west-1.amazonaws.com/1667311330924car%20brands%20icons-05.png",
    },
    {
      id: 1,
      name: "Honda",
      image: "https://assets-autodeals.s3.eu-west-1.amazonaws.com/1667311379445car%20brands%20icons-01.png",
    },
    {
      id: 46,
      name: "Kia",
      image: "https://assets-autodeals.s3.eu-west-1.amazonaws.com/1667310950921car%20brands%20icons-04.png",
    },
    {
      id: 6,
      name: "Daihatsu",
      image: "https://assets-autodeals.s3.eu-west-1.amazonaws.com/1667311350665car%20brands%20icons-11.png",
    },
    {
      id: 9,
      name: "Nissan",
      image: "https://assets-autodeals.s3.eu-west-1.amazonaws.com/1667311313383car%20brands%20icons-02.png",
    },
    // { id: 54, name: "Mercedes", image: require('../../../assets/Images/makers/mercedes.png') },
    // { id: 38, name: "Hyundai",image: require('../../../assets/Images/makers/hyundai.png') },
    // { id: 4, name: "BMW", image: require('../../../assets/Images/makers/bmw.png') },
  ];
  getAllMakes = () => {
    const { category } = this.state;
    this.infoService
      .getAllMakes(category, 9)
      .then((res) => {
        const { data } = res;
        if (data && data.length) {
          this.setState({
            makes: data,
          });
        }
      })
      .catch((err) => {
        this.infoService.handleError(err);
      });
  };

  render() {
    const { unreadChatsCount, active } = this.state;

    return (
      <div id="header-main" className="header" style={{ backgroundColor: "#023047ED" }}>
        {this.props.isAuth === true ? (
          <TopNavigationPrivate onSignOut={this.onSignOutHandler} userProfile={this.props.userProfile} unreadChatsCount={unreadChatsCount}>
            {this.shopingCart()}
          </TopNavigationPrivate>
        ) : (
          <TopNavigation>{this.shopingCart()}</TopNavigation>
        )}
        <div
          style={{
            backgroundColor: "#023047ED",
            borderTop: "5px solid rgba(0,0,0,0.2)",
          }}
        >
          <div className="container desktop-header">
            <Navbar color="" light expand="md" className="py-0">
              <NavbarBrand href="/">
                <img loading="lazy" style={{ width: "auto", height: "60px" }} src={require("../../../assets/Images/logo.png")} alt="logo" />
              </NavbarBrand>
              <NavbarToggler onClick={() => this.toggler()} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <ul className="main-nav d-flex">
                    <li className="top-level-link">
                      <Link to={"/used-cars"} className={`mega-menu p-3 px-4 ${active === 1 ? "active-border" : ""}`} onClick={() => this.setState({ active: 1 })}>
                        <span className="">Used Cars</span>
                      </Link>
                      <div className="sub-menu-block" style={{ borderBottom: "6px solid var(--orange)" }}>
                        <div className="column-3">
                          <div className="private-dropdown-content">
                            <div className="dropdown-ads ">
                              <div className="row">
                                <div className="col-md-3 pt-3 ">
                                  <div className="nav-link-new">
                                    <div className="pr-2 ">
                                      <BsSearch />
                                    </div>
                                    <Link className="p-0" to={"/used-cars/search/-/"}>
                                      <div>
                                        <h5>Find Used Cars </h5>
                                        <p style={{ fontSize: "11px" }}>Search from over 60k options</p>
                                      </div>
                                    </Link>
                                  </div>
                                  <div className="nav-link-new">
                                    <div className="pr-2 ">
                                      <AiOutlineStar />
                                    </div>
                                    <Link className="p-0" to={"/used-cars/search/-/adT_featured"}>
                                      <h5>Featured Cars For Sale</h5>
                                      <p style={{ fontSize: "11px" }}>View Used Featured Cars</p>
                                    </Link>
                                  </div>
                                  <div className="nav-link-new">
                                    <div className="pr-2 ">
                                      <AiOutlineTag />
                                    </div>
                                    <Link className="p-0" to={"/post-ad/sell-car/post-your-ad"}>
                                      <h5>Sell Your Cars</h5>
                                      <p style={{ fontSize: "11px" }}>Post a free ad and sell your car</p>
                                    </Link>
                                    <div className=""></div>
                                  </div>
                                  <div className="nav-link-new border-0">
                                    <div className="pr-2 ">
                                      <BsFileEarmarkSpreadsheet />
                                    </div>
                                    <Link className="p-0" to={"/auction-sheet-verification"}>
                                      <h5>Auction Sheet</h5>
                                      <p style={{ fontSize: "11px" }}>Verify Japenese Auction Sheet</p>
                                    </Link>
                                    <div className=""></div>
                                  </div>
                                </div>
                                <div className="col-md-3 pt-3 border-left border-right">
                                  <div className="nav-link-new">
                                    <div className="pr-2 ">
                                      <BsSearch />
                                    </div>
                                    <Link className="p-0" to={"/used-bikes/search/-/"}>
                                      <div>
                                        <h5>Find Used Bikes </h5>
                                        <p style={{ fontSize: "11px" }}>Search from over 30k options</p>
                                      </div>
                                    </Link>
                                  </div>
                                  <div className="nav-link-new">
                                    <div className="pr-2 ">
                                      <AiOutlineStar />
                                    </div>
                                    <Link className="p-0" to={"/used-bikes/search/-/adT_featured"}>
                                      <h5>Featured Bikes For Sale</h5>
                                      <p style={{ fontSize: "11px" }}>View Used Featured Bikes</p>
                                    </Link>
                                  </div>
                                  <div className="nav-link-new">
                                    <div className="pr-2 ">
                                      <AiOutlineTag />
                                    </div>
                                    <Link className="p-0" to={"/post-ad/sell-bike/post-your-ad"}>
                                      <h5>Sell Your Bike</h5>
                                      <p style={{ fontSize: "11px" }}>Post a free ad and sell your bike</p>
                                    </Link>
                                    <div className=""></div>
                                  </div>
                                  <div className="nav-link-new border-0">
                                    <div className="pr-2 ">
                                      <i className="fas fa-user"></i>
                                    </div>
                                    <Link className="p-0" to={"/used-cars-dealers/search/-/"}>
                                      <h5>Dealers & Showrooms</h5>
                                      <p style={{ fontSize: "11px" }}>Used Cars Dealer</p>
                                    </Link>
                                    <div className=""></div>
                                  </div>
                                </div>

                                <div className="col-md-3 border-right-1px">
                                  <div className="d-flex anim-2">
                                    <p style={{ fontSize: "18px" }} className="mb-0">
                                      <i className="fas fa-car"></i> Popular Makers
                                    </p>
                                  </div>
                                  {this.makes &&
                                    this.makes.map((make, i) => {
                                      return (
                                        <li key={i} className="new-li">
                                          <Link className="p-1 text-muted" to={`/used-cars/search/-${this.commonService.getNameBasedQuery("mk", [make.name + "_" + make.id])}`}>
                                            <div className={`d-flex popular-makes-border ${make.name}`}>
                                              <img
                                                style={{
                                                  width: "auto",
                                                  height: "30px",
                                                }}
                                                className="pr-3"
                                                src={make.image}
                                              />
                                              {make.name}
                                            </div>
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  {/* <Link to={"/used-cars"}>
                                <i className="fa fa-search"></i> Used Cars
                              </Link>
                              <Link to={"/used-cars/search/-/adT_featured"}>
                                <i className="far fa-heart"></i> Featured Cars
                              </Link> */}
                                  {/* <Link to={"/post-ad/sell-car/post-your-ad"}>
                                <i className="fa fa-tag"></i> Sell Your Cars
                              </Link>
                              <Link to={"/auction-sheet-verification"}>
                                <i className="fas fa-file-alt"></i> Auction
                                Sheet Verification
                              </Link> */}
                                </div>
                                <div className="col-md-3 mb-3">
                                  <div className="d-flex anim-2">
                                    <p style={{ fontSize: "18px" }} className="mb-0">
                                      <GrLocation /> Popular Cities
                                    </p>
                                  </div>
                                  {this.cities.map((city, i) => {
                                    return (
                                      <li key={i} className="new-li">
                                        <Link className="m-0 p-1 text-muted" to={`/used-cars/search/-${this.commonService.getNameBasedQuery("ct", [city.name])}`}>
                                          <div className={`d-flex anim-4 p-0 m-0 popular-makes-border ${city.name}`}>
                                            <img loading="lazy" className="pr-3" src={city.image} />
                                            <p className="mb-0">{city.name}</p>
                                          </div>
                                        </Link>
                                      </li>
                                    );
                                  })}
                                  {/* <Link to={"/used-cars-dealers"}>
                                <i className="fas fa-store"></i> Used Car
                                Dealers
                              </Link>
                              
                              <Link to={"/price-calculator"}>
                                {" "}
                                <i className="fa fa-bullseye"></i> Used Price
                                Calculator
                              </Link> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="top-level-link">
                      <Link to={"/new/cars"} className={`mega-menu p-3 px-4 ${active === 2 ? "active-border" : ""}`} onClick={() => this.setState({ active: 2 })}>
                        <span>New Cars</span>
                      </Link>
                      <div
                        className="sub-menu-block"
                        style={{
                          marginLeft: "141px",
                          borderBottom: "6px solid var(--orange)",
                        }}
                      >
                        <div className="column-2">
                          <div className="private-dropdown-content">
                            <div className="dropdown-ads">
                              <div className="row">
                                <div className="col-md-4 border-right-1px">
                                  <Link to={"/new/cars"} className="nav-link-new pb-3">
                                    <div className="d-flex">
                                      <div className="align-self-center mr-3">
                                        <BsSearch />
                                      </div>
                                      <div>
                                        <h5> New Cars</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Search New Cars
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                  <Link to={"/car-comparison"} className="nav-link-new pb-3">
                                    <div className="d-flex">
                                      <div className="align-self-center mr-3">
                                        <BiGitCompare />
                                      </div>
                                      <div>
                                        <h5> Car Comparison</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Compare Car Difference's
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                  {/* <Link to={"/"}><i className="fa fa-comments"></i> Reviews</Link>
                          <Link to={"/"}><i className="fa fa-tags"></i> Prices</Link> */}
                                  <Link to={"/new-cars-dealers/search/-/"} className="nav-link-new pb-3">
                                    {" "}
                                    <div className="d-flex">
                                      <div className="align-self-center mr-3">
                                        <AiOutlineUserSwitch />
                                      </div>
                                      <div>
                                        <h5>New Car Dealers</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Search New Cars Dealers
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                  <Link to={"/new-cars/pricelist"}>
                                    {" "}
                                    <div className="nav-link-new border-0">
                                      <div className="align-self-center mr-3">
                                        <RiPriceTag3Line />
                                      </div>
                                      <div>
                                        <h5>New Cars Prices</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Search New Cars Prices
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                                <div className="col-md-4 border-right-1px pl-2">
                                  <Link to={"/new-cars/search/-/"} className="nav-link-new pb-3">
                                    <div className="d-flex">
                                      <div className="align-self-center mr-3">
                                        <BsSearch />
                                      </div>
                                      <div>
                                        <h5>Search New Cars</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Search All Sorts Of New Cars
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                  <Link to={"/new-cars-dealers/search/-/"} className="nav-link-new pb-3">
                                    <div className="d-flex">
                                      <div className="align-self-center mr-3">
                                        <AiOutlineUserSwitch />
                                      </div>
                                      <div>
                                        <h5>Search New Cars Dealers</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Search New Cars Dealers
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                  <Link to={"/recent-car-reviews"} className="nav-link-new pb-3">
                                    <div className="d-flex">
                                      <div className="align-self-center mr-3">
                                        <FaRegComments />
                                      </div>
                                      <div>
                                        <h5>Car Reviews</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Read Car Reviews
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                  <Link to={"/post-ad/sell-car/post-your-ad"}>
                                    {" "}
                                    <div className="nav-link-new border-0">
                                      <div className="align-self-center mr-3">
                                        <RiPriceTag3Line />
                                      </div>
                                      <div>
                                        <h5>Sell Cars</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Sell Your Cars
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                                <div className="col-md-4 mb-3">
                                  <div className="d-flex anim-2">
                                    <p style={{ fontSize: "18px" }} className="mb-0">
                                      <GrLocation /> Popular Cities
                                    </p>
                                  </div>
                                  {this.cities.map((city, i) => {
                                    return (
                                      <li className="new-li" key={i}>
                                        {" "}
                                        <Link className="m-0 p-1 text-muted" to={`/new-cars/search/-${this.commonService.getNameBasedQuery("ct", [city.name])}`}>
                                          <div className={`d-flex anim-4 p-0 m-0 popular-makes-border ${city.name}`}>
                                            <img loading="lazy" className="pr-3" src={city.image} />
                                            <p className="mb-0">{city.name}</p>
                                          </div>
                                        </Link>
                                      </li>
                                    );
                                  })}
                                  {/* <Link to={"/used-cars-dealers"}>
                                <i className="fas fa-store"></i> Used Car
                                Dealers
                              </Link>
                              
                              <Link to={"/price-calculator"}>
                                {" "}
                                <i className="fa fa-bullseye"></i> Used Price
                                Calculator
                              </Link> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="top-level-link">
                      <Link to={"/used-bikes"} className={`mega-menu p-3 px-4 ${active === 3 ? "active-border" : ""}`} onClick={() => this.setState({ active: 3 })}>
                        <span>Bike</span>
                      </Link>
                      <div
                        className="sub-menu-block"
                        style={{
                          marginLeft: "278px",
                          borderBottom: "6px solid var(--orange)",
                        }}
                      >
                        <div className="column-4">
                          <div className="private-dropdown-content">
                            <div className="dropdown-ads">
                              <div className="row">
                                <div className="col-md-6 border-right-1px">
                                  <div className="nav-link-new">
                                    <div className="pr-3 align-self-center ">
                                      <BsSearch />
                                    </div>
                                    <Link className="p-0" to={"/used-bikes"}>
                                      <div>
                                        <h5>Find Used Bikes </h5>
                                        <p style={{ fontSize: "11px" }}>Search from over 3K options</p>
                                      </div>
                                    </Link>
                                  </div>
                                  <div className="nav-link-new">
                                    <div className="pr-3 align-self-center ">
                                      <BsSearch />
                                    </div>
                                    <Link className="p-0" to={"/new/bikes"}>
                                      <div>
                                        <h5>New Bikes </h5>
                                        <p style={{ fontSize: "11px" }}>Find Your Perfect Ride</p>
                                      </div>
                                    </Link>
                                  </div>
                                  <div className="nav-link-new">
                                    <div className="pr-3 align-self-center ">
                                      <AiOutlineStar />
                                    </div>
                                    <Link className="p-0" to={"/used-bikes/search/-/adT_featured"}>
                                      <div>
                                        <h5>Featured Bikes </h5>
                                        <p style={{ fontSize: "11px" }}>View Featured Used Bikes</p>
                                      </div>
                                    </Link>
                                  </div>{" "}
                                  <div className="nav-link-new border-0">
                                    <div className="align-self-center mr-3">
                                      <RiPriceTag3Line />
                                    </div>
                                    <div className=" mt-2">
                                      <Link to={"/post-ad/sell-bike/post-your-ad"}>
                                        <h5>Post a free Ad</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Sell Your Bikes Now
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="nav-link-new py-2">
                                    <div className="align-self-center mr-2">
                                      <BiGitCompare />
                                    </div>
                                    <div>
                                      <Link to={"/bike-comparison"}>
                                        <h5>Bike Compare</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Compare Bike Difference's
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="nav-link-new py-2">
                                    <div className="align-self-center mr-2">
                                      <AiOutlineTag />
                                    </div>
                                    <div>
                                      <Link to={"/new-bikes/pricelist"}>
                                        <h5>New Bike Prices</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Search New Bike Prices
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="nav-link-new border-0 py-2">
                                    <div className="align-self-center mr-2">
                                      <FaRegComments />
                                    </div>
                                    <div>
                                      <Link to={"/recent-bike-reviews"}>
                                        <h5>Bike Reviews</h5>
                                        <p style={{ fontSize: "11px" }} className="mb-0">
                                          Read Bikes Reviews
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                  {/* <Link to={"/"}><i className="fa fa-tags"></i> Bike Prices</Link>
                              <Link to={"/"}><i className="fa fa-comments"></i> Bike Reviews</Link> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="top-level-link">
                      <Link to={"/accessories-spare-parts"} className={`mega-menu p-3 px-4 ${active === 4 ? "active-border" : ""}`} onClick={() => this.setState({ active: 4 })}>
                        <span>Parts</span>
                      </Link>
                      <div
                        className="sub-menu-block"
                        style={{
                          marginLeft: "376px",
                          borderBottom: "6px solid var(--orange)",
                        }}
                      >
                        <div className="column-1">
                          <div className="private-dropdown-content">
                            <div className="dropdown-ads">
                              <div className="nav-link-new py-2">
                                <div className="align-self-center mr-2">
                                  <BsSearch />
                                </div>
                                <div>
                                  <Link to={"/accessories-spare-parts/search/-/"}>
                                    <h5>Find Auto Parts</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Find New or Used Car & Bike Parts
                                    </p>
                                  </Link>
                                </div>
                              </div>
                              <div className="nav-link-new py-2">
                                <div className="align-self-center mr-2">
                                  <BsSearch />
                                </div>
                                <div>
                                  <Link to={"/shop"} target="_blank">
                                    <h5>AutoDeals Shop</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Find the New Parts
                                    </p>
                                  </Link>
                                </div>
                              </div>
                              <div className="nav-link-new py-2">
                                <div className="align-self-center mr-2">
                                  <BsSearch />
                                </div>
                                <div>
                                  <Link to={"/accessories-spare-parts/search/-/sId_autodeals_37"}>
                                    <h5>AutoDeals Store</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Buy Car & Bikes Accessories From AD
                                    </p>
                                  </Link>
                                </div>
                              </div>
                              <div className="nav-link-new py-2 border-0">
                                <div className="align-self-center mr-2">
                                  <BsSearch />
                                </div>
                                <div>
                                  <Link to={"/post-ad/sell-part"} className="border-0">
                                    <h5>Sell New & Used Parts</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Post a Free Ads & Sell it
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="top-level-link">
                      <Link className={`mega-menu p-3 px-4 ${active === 5 ? "active-border" : ""} `} onClick={() => this.setState({ active: 6 })}>
                        <span>More</span>
                      </Link>
                      <div
                        className="sub-menu-block"
                        style={{
                          marginLeft: "482px",
                          borderBottom: "6px solid var(--orange)",
                        }}
                      >
                        <div className="column-1">
                          <div className="private-dropdown-content">
                            <div className="dropdown-ads flex-column d-flex">
                              <Link to={"/car-import/"} className="text-muted border-bottom py-3">
                                <div className="d-flex">
                                  <img
                                    className="mr-3"
                                    src={require("../../../assets/Images/explore-icons/vehile-insurance.png")}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <div>
                                    <h5>Car Import</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Calculate custom duty on imported cars
                                    </p>
                                  </div>
                                </div>
                              </Link>
                              <Link to={"/car-import/custom-duty-calculator"} className="text-muted border-bottom py-3">
                                <div className="d-flex">
                                  <img
                                    className="mr-3"
                                    src={require("../../../assets/Images/explore-icons/vehile-insurance.png")}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <div>
                                    <h5>Custom Duty Calculator</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Calculate custom duty on imported cars
                                    </p>
                                  </div>
                                </div>
                              </Link>                              
                              <Link to={"/car-financing/car-loan-calculator"} className="text-muted border-bottom py-3">
                                <div className="d-flex">
                                  <img
                                    className="mr-3"
                                    src={require("../../../assets/Images/explore-icons/car-finance-calculator.png")}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <div>
                                    <h5>Car Finance Calculator</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Get your dream car on easy installments
                                    </p>
                                  </div>
                                </div>
                              </Link>
                              <Link to={"/price-calculator"} className="text-muted border-bottom py-3">
                                {" "}
                                <div className="d-flex">
                                  <img
                                    className="mr-3"
                                    src={require("../../../assets/Images/explore-icons/used-car-calculate.png")}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <div>
                                    <h5>Used Price Calculator</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Used car price evaluation tool
                                    </p>
                                  </div>
                                </div>
                              </Link>
                              <Link to={"/car-insurance"} className="text-muted border-bottom py-3">
                                {" "}
                                <div className="d-flex">
                                  <img
                                    className="mr-3"
                                    src={require("../../../assets/Images/explore-icons/vehile-insurance.png")}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <div>
                                    <h5>Car Insurance</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Get car insurance & secure your car
                                    </p>
                                  </div>
                                </div>
                              </Link>
                              <Link to={"/car-tracker"} className="text-muted border-bottom py-3">
                                <div className="d-flex">
                                  <img
                                    className="mr-3"
                                    src={require("../../../assets/Images/explore-icons/vehile-tracker.png")}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <div>
                                    <h5>Car Tracker</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Secure your car, get a tracker
                                    </p>
                                  </div>
                                </div>
                              </Link>
                              <Link to={"/petrol-prices-in-pakistan"} className="text-muted border-0 py-3">
                                <div className="d-flex">
                                  <img
                                    className="mr-3"
                                    src={require("../../../assets/Images/explore-icons/vehile-tracker.png")}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <div>
                                    <h5>Fuel Prices</h5>
                                    <p style={{ fontSize: "11px" }} className="mb-0">
                                      Find out latest fuel prices in Pakistan
                                    </p>
                                  </div>
                                </div>
                              </Link>
                              {/* <Link to={"/videos"} className="text-muted py-3">
                                                                <img loading="lazy" src={require("../../../assets/Images/explore-icons/vehile-insurance.png")} style={{ width: "30px", height: "30px", objectFit: "contain" }} />
&nbsp;Videos
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="top-level-link">
                      <a href="/blog" className={`mega-menu p-3 px-2  text-decoration-none`}>
                        <span>Blog</span>
                      </a>
                      <div
                        className="sub-menu-block"
                        style={{
                          marginLeft: "587px",
                          borderBottom: "6px solid var(--orange)",
                        }}
                      >
                        <div className="column-1">
                          <div className="private-dropdown-content">
                            <div className="dropdown-ads flex-column d-flex">
                              <a target="_blank" href="https://autodeals.pk/blog/category/auction-sheet/" className="text-dark border-bottom py-2">
                                <h5>Auction Sheet</h5>
                                <p style={{ fontSize: "11px" }} className="mb-0">
                                  Verification guid & explaination
                                </p>
                              </a>
                              <a target="_blank" href="https://autodeals.pk/blog/category/news/" className="text-dark border-bottom py-2">
                                <h5>Automotive News</h5>
                                <p style={{ fontSize: "11px" }} className="mb-0">
                                  Latest news & updates
                                </p>
                              </a>
                              <a target="_blank" href="https://autodeals.pk/blog/category/pakistan-automotive-industry/" className="text-dark border-bottom py-2">
                                <h5>Pakistan Automotive Industry</h5>
                                <p style={{ fontSize: "11px" }} className="mb-0">
                                  Latest news Pakistani automotives
                                </p>
                              </a>
                              <a target="_blank" href="https://autodeals.pk/blog/category/worldwide-automotive-industry/" className="text-dark border-bottom py-2">
                                <h5>WorldWide Automotive Industry</h5>
                                <p style={{ fontSize: "11px" }} className="mb-0">
                                  Latest automotive news across the globe
                                </p>
                              </a>
                              <a target="_blank" href="https://autodeals.pk/blog/category/automotive-gadgets/" className="text-dark border-bottom py-2">
                                <h5>Automotive Gadgets</h5>
                                <p style={{ fontSize: "11px" }} className="mb-0">
                                  Find out automotive gadgets & accessories
                                </p>
                              </a>
                              <a target="_blank" href="https://autodeals.pk/blog/category/how-to/" className="text-dark border-bottom py-2">
                                <h5>How to?</h5>
                                <p style={{ fontSize: "11px" }} className="mb-0">
                                  Know more about your car
                                </p>
                              </a>
                              <a target="_blank" href="https://autodeals.pk/blog/category/new-cars/" className="text-dark py-2">
                                <h5>New Cars</h5>
                                <p style={{ fontSize: "11px" }} className="mb-0">
                                  Find out newly launched cars in Pakistan
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  {/* <UncontrolledDropdown
                  className="d-inline-block menu-links"
                  isOpen={this.state.dropdownOpen === "5"}
                  onMouseEnter={() => this.toggle("5")}
                  onMouseLeave={() => this.toggle("0")}
                  toggle={this.toggle}
                >
                  <DropdownToggle
                    nav
                    tag={Link}
                    to={"/auction-sheet-verification"}
                  >
                    <span className="more-links">Services <GoTriangleDown /></span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-2">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <div className="row">
                            <div className="col-md-6 border-right-1px">
                              <Link to={"/auction-sheet-verification"}>
                                <i className="fas fa-file-alt"></i> Auction
                                Sheet Verification
                              </Link>
                              <Link to={"/car-financing/car-loan-calculator"}>
                                <i className="far fa-money-bill-alt"></i> Car
                                Finance
                              </Link>
                              <Link to={"/car-tracker"}>
                                <i className="fas fa-exclamation-circle"></i>{" "}
                                Car Tracker
                              </Link>
                              <Link to={"/car-insurance"}>
                                <i className="fa fa-umbrella"></i> Car Insurance
                              </Link>
                            </div>
                            <div className="col-md-6">
                              <Link to={"/car-import"}>
                                <i className="fas fa-level-down-alt"></i> Import
                                Cars
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}

                  {/* <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === '6'} onMouseEnter={() => this.toggle('6')} onMouseLeave={() => this.toggle('0')} toggle={this.toggle}>
                  <DropdownToggle nav tag={Link} to={"/product-listing/car"}>
                    <span className="more-links">Blog</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-2">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <div className="row">
                            <div className="col-md-6 border-right-1px">
                              <Link to={"/"}><i className="far fa-newspaper"></i> News</Link>
                              <Link to={"/"}><i className="far fa-flag"></i> Pakistan Auto Industry</Link>
                              <Link to={"/"}><i className="fas fa-globe-asia"></i> Global Auto Industry</Link>
                              <Link to={"/"}><i className="far fa-edit"></i> Write for us</Link>
                              <Link to={"/"}><i className="fas fa-info-circle"></i> Auto Tips</Link>
                            </div>
                            <div className="col-md-6">
                              <Link to={"/makes"}><i className="fa fa-search"></i> New by Make</Link>
                              <Link to={"/"}><i className="far fa-check-circle"></i> Gadgets</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}

                  {/* <NavItem>
                  <NavLink tag={Link} to={"/rides"}>Rides</NavLink>
                </NavItem> */}

                  <NavItem className={`px-1 pb-3 mb-n3 ${active === 6 ? "active-border" : ""}`} onClick={() => this.setState({ active: 6 })}>
                    <NavLink tag={Link} to={"/videos"}>
                      Videos
                    </NavLink>
                  </NavItem>

                  {/*<UncontrolledDropdown
                  className="d-inline-block menu-links"
                  isOpen={this.state.dropdownOpen === "8"}
                  onMouseEnter={() => this.toggle("8")}
                  onMouseLeave={() => this.toggle("0")}
                  toggle={this.toggle}
                >
                  <DropdownToggle nav tag={Link} to={"/videos"}>
                    <span className="more-links">Videos</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-1">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <Link to={"/videos"}><i className="far fa-smile-beam"></i> First Look</Link>
                          <Link to={"/"}><i className="fa fa-comments"></i> Car Reviews</Link>
                          <Link to={"/"}><i className="fa fa-comments"></i> Bike Reviews</Link>
                          <Link to={"/"}><i className="far fa-newspaper"></i> News</Link>
                          <Link to={"/"}><i className="far fa-check-circle"></i> Tips</Link>
                          <Link to={"/"}><i className="far fa-check-circle"></i> Gadgets Review</Link>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>*/}

                  {/* <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === '9'} onMouseEnter={() => this.toggle('9')} onMouseLeave={() => this.toggle('0')} toggle={this.toggle}>
                  <DropdownToggle nav >
                    <span className="more-links">More</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-2 more-dropdown" direction="right">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <div className="row">
                            <div className="col-md-6 border-right-1px">
                              <Link to={"/"}><i className="fa fa-book"></i> Car Dealers</Link>
                              <Link to={"/"}><i className="fas fa-file-alt"></i> FAQs</Link>
                              <Link to={"/about-us"}><i className="fas fa-atlas"></i> About Us</Link>
                              <Link to={"/"}><i className="fab fa-amazon-pay"></i> How to Pay</Link>
                              <Link to={"/"}><i className="far fa-handshake"></i> Career</Link>
                            </div>
                            <div className="col-md-6">
                              <Link to={"/"}><i className="fas fa-retweet"></i> Refund & Returns</Link>
                              <Link to={"/"}><i className="fas fa-ad"></i> Advertise With Us</Link>
                              <Link to={"/about-us"}><i className="fas fa-map-signs"></i>Contact Us</Link>
                            </div>
                          </div>
                          <Link to={"/auctionsheet-verification"}>AuctionSheet Verification</Link>
                          <Link to={"/certified-cars"}>Certified Cars</Link>
                          <Link to={"/featured-ad-plans"}>Featured Ads Plan</Link>
                          <Link to={"/feature-your-ads"}>Feature Your Ads</Link>
                          <Link to={"/new-cars/listing"}>New Car Listing</Link>
                          <Link to={"/rides"}>Rides</Link>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>  */}

                  <UncontrolledDropdown nav inNavbar className="pl-3">
                    <DropdownToggle nav >
                      {/* <div className="cart-image">
                      <img loading="lazy" src={require("../../../assets/Images/cart.png")} alt="cart" />
                    </div> */}
                      <span style={{ fontSize: "14px" }}>
                        Post Free Ad <GoTriangleDown />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu
                      right
                      style={{
                        borderRadius: "10px",
                        boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
                      }}
                    >
                      <DropdownItem onClick={() => this.sellNow("/post-ad/sell-car/post-your-ad")}>
                        <div className="image">
                          <img loading="lazy" src={require("../../../assets/Images/car.png")} alt="car" />
                        </div>
                        <span>Sell Car</span>
                      </DropdownItem>
                      <DropdownItem onClick={() => this.sellNow("/post-ad/sell-bike")}>
                        <div className="image">
                          <img loading="lazy" src={require("../../../assets/Images/bike.png")} alt="bike" />
                        </div>
                        <span>Sell Bike</span>
                      </DropdownItem>
                      <DropdownItem onClick={() => this.sellNow("/post-ad/sell-part")}>
                        <div className="image">
                          <img loading="lazy" src={require("../../../assets/Images/part.png")} alt="part" />
                        </div>
                        <span>Sell Auto Part</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </div>

        <div className="container mobile-header">
          <Navbar color="" light expand="md">
            <NavbarBrand href="/">
              <img loading="lazy" src={require("../../../assets/Images/logo.png")} alt="logo" />
            </NavbarBrand>
            <div className="custom-sell-btn">
              <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === "10"} onClick={(e) => this.toggle(this.state.dropdownOpen === "10" ? "0" : "10")}>
                <DropdownToggle nav caret>
                  {/* <div className="cart-image">
                    <img loading="lazy" src={require("../../../assets/Images/cart.png")} alt="cart" />
                  </div> */}
                  <span className="more-links">Post And Ad</span>
                </DropdownToggle>
                <DropdownMenu right className="column-2 more-dropdown" direction="right">
                  <DropdownItem>
                    <div className="private-dropdown-content">
                      <div className="dropdown-ads">
                        <Link to={"/post-ad/sell-car/post-your-ad"}>
                          <div className="image">
                            <img loading="lazy" src={require("../../../assets/Images/car.png")} alt="car" />
                          </div>
                          Sell Car
                        </Link>
                        <Link to={"/post-ad/sell-bike"}>
                          <div className="image">
                            <img loading="lazy" src={require("../../../assets/Images/bike.png")} alt="part" />
                          </div>
                          Sell Bike
                        </Link>
                        <Link to={"/post-ad/sell-part"}>
                          <div className="image">
                            <img loading="lazy" src={require("../../../assets/Images/part.png")} alt="part" />
                          </div>
                          Sell Auto Part
                        </Link>
                      </div>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <NavbarToggler onClick={() => this.toggler()} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === "1"} onClick={(e) => this.toggle(this.state.dropdownOpen === "1" ? "0" : "1")}>
                  <DropdownToggle nav caret>
                    <span className="more-links">Used Cars</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-1">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <Link onClick={() => this.toggler()} to={"/used-cars"}>
                            <i className="fa fa-search"></i> Used Cars
                          </Link>
                          <Link onClick={() => this.toggler()} to={"/used-cars/search/-/adT_featured"}>
                            <i className="far fa-heart"></i> Featured Cars
                          </Link>
                          {/* <Link
                            onClick={() => this.toggler()}
                            to={"/certified-cars"}
                          >
                            <i className="fa fa-certificate"></i> Certified Cars
                          </Link> */}
                          <Link onClick={() => this.toggler()} to={"/post-ad/sell-car/post-your-ad"}>
                            <i className="fa fa-tag"></i> Sell Your Cars
                          </Link>
                          <Link onClick={() => this.toggler()} to={"/new-cars-dealers"}>
                            <i className="fa fa-book"></i> Used Car Dealers
                          </Link>
                          {/* <Link
                            onClick={() => this.toggler()}
                            to={"/car-inspection"}
                          >
                            <i className="far fa-thumbs-up"></i> Used Car
                            Inspection
                          </Link> */}
                          <Link onClick={() => this.toggler()} to={"/price-calculator"}>
                            {" "}
                            <i className="fa fa-bullseye"></i> Used Price Calculator
                          </Link>
                          <Link onClick={() => this.toggler()} to={"/auction-sheet-verification"}>
                            <i className="fas fa-file-alt"></i> Auction Sheet Verification
                          </Link>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === "2"} onClick={(e) => this.toggle(this.state.dropdownOpen === "2" ? "0" : "2")}>
                  <DropdownToggle nav caret>
                    <span className="more-links">New Cars</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-1">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <Link onClick={() => this.toggler()} to={"/new/cars"}>
                            <i className="fa fa-search"></i> New Cars
                          </Link>
                          <Link onClick={() => this.toggler()} to={"/car-comparison"}>
                            <i className="fa fa-columns"></i> Car Comparison
                          </Link>
                          {/* <Link onClick={() => this.toggler()} to={"/"}><i className="fa fa-comments"></i> Reviews</Link>
                          <Link onClick={() => this.toggler()} to={"/"}><i className="fa fa-tags"></i> Prices</Link> */}
                          <Link onClick={() => this.toggler()} to={"/new-cars-dealers"}>
                            {" "}
                            <i className="fas fa-store"></i> New Car Showrooms
                          </Link>
                          <Link onClick={() => this.toggler()} to={"/new-cars/pricelist"}>
                            {" "}
                            <i className="fas fa-tag"></i> New Cars Prices
                          </Link>
                          <Link onClick={() => this.toggler()} to={"/recent-car-reviews"}>
                            <i className="fa fa-comments"></i> Car Reviews
                          </Link>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === "3"} onClick={(e) => this.toggle(this.state.dropdownOpen === "3" ? "0" : "3")}>
                  <DropdownToggle nav caret>
                    <span className="more-links">Bikes</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-2">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <div className="row">
                            <div className="col-md-6">
                              <Link onClick={() => this.toggler()} to={"/used-bikes"}>
                                <i className="fa fa-search"></i> Used Bikes
                              </Link>
                              <Link onClick={() => this.toggler()} to={"/new/bikes"}>
                                <i className="fa fa-search"></i> New Bikes
                              </Link>
                              <Link onClick={() => this.toggler()} to={"/used-bikes/search/-/adT_featured"}>
                                <i className="far fa-heart"></i> Featured Bikes
                              </Link>
                              <Link onClick={() => this.toggler()} to={"/post-ad/sell-bike"}>
                                <i className="fa fa-tag"></i>Sell Your Bike
                              </Link>
                              <Link onClick={() => this.toggler()} to={"/bike-comparison"}>
                                <i className="fa fa-columns"></i> Bike Comparison
                              </Link>
                              <Link onClick={() => this.toggler()} to={"/new-bikes/pricelist"}>
                                <i className="fas fa-tag"></i> New Bikes Prices
                              </Link>
                              <Link onClick={() => this.toggler()} to={"/recent-bike-reviews"}>
                                <i className="fa fa-comments"></i> Bike Reviews
                              </Link>
                            </div>
                            <div className="col-md-6">
                              {/* <Link onClick={() => this.toggler()} to={"/"}><i className="fa fa-tags"></i> Bike Prices</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fa fa-comments"></i> Bike Reviews</Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === "4"} onClick={(e) => this.toggle(this.state.dropdownOpen === "4" ? "0" : "4")}>
                  <DropdownToggle nav caret>
                    <span className="more-links">Parts</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-1">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <Link onClick={() => this.toggler()} to={"/accessories-spare-parts"}>
                            <i className="fa fa-search"></i> Find Auto Parts
                          </Link>
                          <Link onClick={() => this.toggler()} to={"/accessories-spare-parts/search/-/sId_autodeals_37"}>
                            <i className="fas fa-store"></i> Auto Deals Store
                          </Link>
                          <Link onClick={() => this.toggler()} to={"/post-ad/sell-part"}>
                            {" "}
                            <i className="fa fa-tag"></i>Sell Your Parts
                          </Link>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === "5"} onClick={(e) => this.toggle(this.state.dropdownOpen === "5" ? "0" : "5")}>
                  <DropdownToggle nav caret>
                    <span className="more-links">Services</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-2">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <div className="row">
                            <div className="col-md-6">
                              <Link onClick={() => this.toggler()} to={"/auction-sheet-verification"}>
                                <i className="fas fa-file-alt"></i> Auction Sheet Verification
                              </Link>
                              {/* <Link
                                onClick={() => this.toggler()}
                                to={"/car-inspection"}
                              >
                                <i className="far fa-thumbs-up"></i> Car
                                Inspection
                              </Link> */}
                              <Link onClick={() => this.toggler()} to={"/car-insurance"}>
                                <i className="fa fa-umbrella"></i> Car Insurance
                              </Link>
                              <Link onClick={() => this.toggler()} to={"/car-financing"}>
                                <i className="far fa-money-bill-alt"></i> Car Finance
                              </Link>
                              <Link onClick={() => this.toggler()} to={"/car-tracker"}>
                                <i className="fas fa-exclamation-circle"></i> Car Tracker
                              </Link>
                              <Link onClick={() => this.toggler()} to={"/car-import"}>
                                <i className="fas fa-level-down-alt"></i> Import Cars
                              </Link>
                            </div>
                            <div className="col-md-6">
                              {/* <Link onClick={() => this.toggler()} to={"/"}><i className="far fa-check-circle"></i> Custom Clearance</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fas fa-warehouse"></i> Auction House</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fas fa-receipt"></i> Car Registration</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fas fa-level-up-alt"></i> Car Export</Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                {/* <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === '6'} onClick={(e) => this.toggle(this.state.dropdownOpen === '6' ? '0' : '6')}>
                  <DropdownToggle nav caret>
                    <span className="more-links">Blog</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-2">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <div className="row">
                            <div className="col-md-6">
                              <Link onClick={() => this.toggler()} to={"/"}><i className="far fa-newspaper"></i> News</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="far fa-flag"></i> Pakistan Auto Industry</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fas fa-globe-asia"></i> Global Auto Industry</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="far fa-edit"></i> Write for us</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fas fa-info-circle"></i> Auto Tips</Link>
                            </div>
                            <div className="col-md-6">
                              <Link onClick={() => this.toggler()} to={"/makes"}><i className="fa fa-search"></i> New by Make</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="far fa-check-circle"></i> Gadgets</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}

                <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === "7"} onClick={(e) => this.toggle(this.state.dropdownOpen === "7" ? "0" : "7")}>
                  <DropdownToggle nav caret>
                    <span className="more-links">Calculator</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-1">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <Link onClick={() => this.toggler()} to={"/car-import/custom-duty-calculator"}>
                            <i className="fa fa-bullseye"></i> Custom Duty Calculator
                          </Link>
                          <Link onClick={() => this.toggler()} to={"/car-financing"}>
                            <i className="fa fa-bullseye"></i> Car Finance Calculator
                          </Link>
                          <Link onClick={() => this.toggler()} to={"/price-calculator"}>
                            {" "}
                            <i className="fa fa-bullseye"></i> Used Price Calculator
                          </Link>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <NavItem>
                  <NavLink tag={Link} onClick={this.redirectToBlog}>
                    Blog
                  </NavLink>
                </NavItem>

                {/*<UncontrolledDropdown
                  className="d-inline-block menu-links"
                  isOpen={this.state.dropdownOpen === "8"}
                  onClick={(e) =>
                    this.toggle(this.state.dropdownOpen === "8" ? "0" : "8")
                  }
                >
                  <DropdownToggle nav tag={Link} to={"/videos"}>
                    <span className="more-links">Videos</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-1">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <Link onClick={() => this.toggler()} to={"/videos"}><i className="far fa-smile-beam"></i> First Look</Link>
                          <Link onClick={() => this.toggler()} to={"/"}><i className="fa fa-comments"></i> Car Reviews</Link>
                          <Link onClick={() => this.toggler()} to={"/"}><i className="fa fa-comments"></i> Bike Reviews</Link>
                          <Link onClick={() => this.toggler()} to={"/"}><i className="far fa-newspaper"></i> News</Link>
                          <Link onClick={() => this.toggler()} to={"/"}><i className="far fa-check-circle"></i> Tips</Link>
                          <Link onClick={() => this.toggler()} to={"/"}><i className="far fa-check-circle"></i> Gadgets Review</Link>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>*/}

                {/* <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === '9'} onClick={(e) => this.toggle(this.state.dropdownOpen === '9' ? '0' : '9')}>
                  <DropdownToggle nav caret>
                    <span className="more-links">More</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-2 more-dropdown" direction="right">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <div className="row">
                            <div className="col-md-6">
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fa fa-book"></i> Car Dealers</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fas fa-file-alt"></i> FAQs</Link>
                              <Link onClick={() => this.toggler()} to={"/about-us"}><i className="fas fa-atlas"></i> About Us</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fab fa-amazon-pay"></i> How to Pay</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="far fa-handshake"></i> Career</Link>
                            </div>
                            <div className="col-md-6">
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fas fa-retweet"></i> Refund & Returns</Link>
                              <Link onClick={() => this.toggler()} to={"/"}><i className="fas fa-ad"></i> Advertise With Us</Link>
                              <Link onClick={() => this.toggler()} to={"/about-us"}><i className="fas fa-map-signs"></i>Contact Us</Link>
                            </div>
                          </div>
                          <Link to={"/auctionsheet-verification"}>AuctionSheet Verification</Link>
                          <Link to={"/certified-cars"}>Certified Cars</Link>
                          <Link to={"/featured-ad-plans"}>Featured Ads Plan</Link>
                          <Link to={"/feature-your-ads"}>Feature Your Ads</Link>
                          <Link to={"/new-cars/listing"}>New Car Listing</Link>
                          <Link to={"/rides"}>Rides</Link>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}

                {/* <NavItem>
                  <NavLink tag={Link} to={"/"}>MORE</NavLink>
                </NavItem> */}
                {/* <UncontrolledDropdown className="d-inline-block menu-links" isOpen={this.state.dropdownOpen === '10'} onClick={(e) => this.toggle(this.state.dropdownOpen === '10' ? '0' : '10')}>
                  <DropdownToggle nav caret>
                    <span className="more-links">Sell Now</span>
                  </DropdownToggle>
                  <DropdownMenu right className="column-2 more-dropdown" direction="right">
                    <DropdownItem>
                      <div className="private-dropdown-content">
                        <div className="dropdown-ads">
                          <Link to={"/post-ad/sell-car/post-your-ad"}>
                            <div className="image">
                              <img loading="lazy" src={require("../../../assets/Images/carmob.png")} alt="car" />
                            </div>
                            Sell Car
                          </Link>
                          <Link to={"/post-ad/sell-bike"}>
                            <div className="image">
                              <img loading="lazy" src={require("../../../assets/Images/bikemob.png")} alt="part" />
                            </div>
                            Sell Bike
                          </Link>
                          <Link to={"/post-ad/sell-part"}>
                            <div className="image">
                              <img loading="lazy" src={require("../../../assets/Images/partmob.png")} alt="part" />
                            </div>
                            Sell Auto Part
                          </Link>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
              </Nav>
            </Collapse>
          </Navbar>
        </div>
        {/* {this.getHomeBanner()}

         */}
        {this.getBreadcrumb()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth ? state.auth.isAuth : false,
    userProfile: state.profile ? state.profile.profileData : {},
    cartItems: state.cart ? state.cart.cartItems : [],
    socket: state.socket ? state.socket.socket : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthStatus: (status) => dispatch(actions.setAuthStatus(status)),
    setProfile: (profileData) => dispatch(actions.setProfile(profileData)),
    setCartItems: (cart) => dispatch(actions.setCartItems(cart)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
